@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oxanium:wght@300;400;500;600;700&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  padding-bottom: env(safe-area-inset-bottom);
}

* {
  font-family: 'Poppins', sans-serif;
}

.selected-customer-block .customer-logo svg {
  width: 130px;
  height: 30px;
}

.drawer-content .provider-logo svg {
  width: 160px;
  height: 80px;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
}
::-webkit-scrollbar-track {
  width: 8px;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #c1c1c2;
  width: 8px;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 3px 2px;
  border-radius: 50%;
  background-color: #35446b;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

div#hubspot-messages-iframe-container {
  display: none !important;
}

/* GANTT STYLES */

.b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
  justify-content: flex-start !important;
}

.b-mask-content {
  display: none !important;
}

.b-grid-splitter-button-collapse,
.b-grid-splitter-button-expand {
  display: none !important;
}

.b-grid-header,
.b-sch-header-timeaxis-cell {
  cursor: auto !important;
}
.b-grid-header-text-content {
  white-space: break-spaces;
}
.b-highlight-grid-row {
  background: rgba(33, 150, 243, 0.08) !important;
}

.b-schedulerbase {
  min-height: 5em !important;
}

@media (max-height: 420px) {
  .b-schedulerbase {
    height: 100% !important;
  }
  .gantt-action-bar {
    display: none !important;
  }
}

.unassigned-resource-wrapper {
  background: rgba(0, 17, 34, 0.04);
  border: 1px solid rgba(0, 17, 34, 0.08);
}

.b-resource-name-container {
  height: 100%;
  cursor: pointer;
}

.b-check-cell {
  background: #fff !important;
}

.b-checkbox > .b-field-inner .b-checkbox-label:not(.b-radio-label):before {
  width: 18px;
  height: 18px;
  color: transparent !important;
  border: 2px solid rgba(0, 17, 34, 0.6);
}
.b-checkbox
  > .b-field-inner
  input[type='checkbox']:checked
  + .b-checkbox-label:before {
  background-color: #2b64cb !important;
  border-color: #2b64cb !important;
  color: #fff !important;
  border-radius: 2px;
}

.b-check-header-with-checkbox {
  justify-content: end !important;
  padding-bottom: 10px !important;
}

.b-grid-row.b-selected {
  background-color: rgba(33, 150, 243, 0.08) !important;
}

.b-grid-row.b-hover {
  background-color: rgba(33, 150, 243, 0.08) !important;
}

.b-gridbase:not(.b-moving-splitter)
  .b-grid-subgrid:not(.b-timeaxissubgrid)
  .b-grid-row:not(.b-group-row).b-hover
  .b-grid-cell {
  background-color: rgba(33, 150, 243, 0.08) !important;
}
.b-selected .b-checkbox-selection {
  background-color: rgba(33, 150, 243, 0.08) !important;
}

.resource-locked {
  background: #2b64cb !important;
}
.resource-locked * {
  color: #fff !important;
}

.all-resource-lock-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.conflicted-driver-row {
  background-color: rgba(244, 67, 54, 0.08) !important;
}

.optimize-gantt-loader {
  filter: contrast(70%);
  pointer-events: none;
  color: grey;
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  animation: shimmer 2s infinite linear;
  background-repeat: no-repeat;
}
@keyframes optimize-gantt-loader {
  100% {
    -webkit-mask-position: left;
  }
}

.resizer {
  cursor: row-resize;
  height: 5px;
  background: #cccc;
  border-radius: 10px;
  margin-top: 4px;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  z-index: 9;
}

.optimization-gantt-column-header {
  background: #ffffff !important;
  height: 64px;
}
.optimization-gantt-timeline-header {
  background: #ffffff !important;
  height: 20px;
}

.b-yscroll-pad {
  display: none !important;
}

.b-grid-header-children {
  height: 40px;
}

.b-header-column-box p {
  font-size: 14px !important;
}
.b-grid-header-container .b-grid-splitter {
  background: #fff !important;
}
.b-grid-cell {
  border: none !important;
}
.b-grid-headers-locked .b-grid-header {
  border: none;
}
.b-grid-headers-locked .b-sortable {
  border: none;
}
.b-grid-headers-locked .b-sortable:hover {
  border: 1px solid #b0b0b7;
  border-style: none solid none solid;
}

.b-check-header-with-checkbox {
  border: none !important;
  border-top: 1px solid #b0b0b7 !important;
  margin-top: 25px;
  flex-basis: 3em !important;
  min-width: 3em !important;
}
.b-grid-row .b-checkbox-selection {
  flex-basis: 3em !important;
  min-width: 3em !important;
}

.b-gridbase:not(.b-column-resizing):not(.b-row-reordering)
  .b-grid-header-container:not(.b-dragging-header)
  .b-depth-0:focus,
.b-gridbase:not(.b-column-resizing):not(.b-row-reordering)
  .b-grid-header-container:not(.b-dragging-header)
  .b-depth-0.b-check-header-with-checkbox:focus-within {
  background: inherit;
}
.b-grid-row:not(.b-selected) .b-grid-cell.b-selected {
  background-color: inherit !important;
}

.b-column-full-height {
  height: 100%;
}

.b-sch-foreground-canvas .b-sch-current-time label {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.b-auto-container {
  gap: 0;
}
.header-lock:hover * {
  color: #fff;
}

.trimblemaps-popup {
  max-width: fit-content !important;
}
.trimblemaps-popup-content {
  padding: 0;
}
.trimblemaps-popup-close-button {
  margin: 10px;
  font-size: 22px;
}

@keyframes error-glow {
  from {
    box-shadow: 0 0 10px -10px rgb(237, 80, 12);
  }
  to {
    box-shadow: 0 0 10px 10px #592307;
  }
}
