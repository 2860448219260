#mapLocation {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#mapLocation .trimblemaps-popup .trimblemaps-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 168px;
  height: 60px;
  color: '#FFFFFF !important';
  /* Uncategorized/Tooltip */
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #1f2e3d;
  border-radius: 4px;
  z-index: 99;
}

#mapLocation .trimblemaps-popup-anchor-bottom .trimblemaps-popup-tip {
  border-top-color: #fff0 !important;
}
#mapLocation .trimblemaps-draw_polygon::after {
  content: 'Draw Boundary';
}

#mapLocation .trimblemaps-draw_polygon {
  background: #2b64cb !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px -2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
  width: 153px !important;
  position: absolute !important;
  left: -160px;
  color: white !important;
}
