[aria-labelledby='pick-icon-label'] {
  display: flex;
  flex-wrap: wrap;
}

#pick-icon svg {
  float: right;
}

#pick-icon-input {
  font-size: 16px;
  margin-top: 20px;
  -webkit-transform: none;
  transform: none;
  -webkit-transition: none;
  transition: none;
}
