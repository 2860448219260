.b-grid-row.pavel {
  background: #ffcd2c;
}
.b-grid-row {
  border-bottom: 1px solid rgba(0, 17, 34, 0.23) !important;
}

.gantt-header-column {
  background-color: #ebebeb;
}

.add-load {
  background: inherit !important;
}

.add-load:hover {
  visibility: visible;
}

.b-sch-event-content {
  width: 100% !important;
  margin: 0 !important;
}

/* .b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
  background: #f7f7f7;
} */
.b-grid-header-scroller {
  background: #f7f7f7 !important;
}
.b-sch-current-time {
  background-color: #2b64cb !important;
}
.b-timeline-subgrid .b-sch-line {
  border-left: 2px dotted #2b64cb !important;
  color: #fff;
  padding: 0;
}
/*

.b-sch-current-green {
  background-color: green !important;
}

.day-timeline {
  background-color: #fff !important;
}
.night-timeline {
  background-color: #000 !important;
}

/* .b-sch-event:not(.b-milestone).b-sch-event-selected .b-sch-event-content * {
	color: #000 !important;
} */

/* .b-sch-event:not(.b-milestone).b-sch-event-selected .eventbox-content * {
  color: #fff;
}

.b-sch-event:not(.b-milestone).b-sch-event-selected .eventimelines-container * {
  color: #000 !important;
} */

.b-sch-event:hover {
  background-color: inherit !important;
  background-image: none !important;
}
.b-sch-event.b-sch-event-selected {
  background-image: none !important;
}
.b-react-portal-container {
  width: 100%;
}
.b-grid-splitter {
  background: #fff !important;
  border: 1px dashed rgba(78, 101, 120, 0.55) !important;
}

.b-grid-splitter-inner {
  background: none !important;
}

/* .b-grid-header-container {
  border-bottom: none !important;
} */

.b-sch-event-tooltip {
  max-width: 100% !important;
}

.b-tooltip {
  border-radius: 8px !important;
}

.b-tooltip-body-wrap {
  margin: 0;
  border-radius: 8px !important;
}

.b-tooltip-content {
  background: #000 !important;
  margin: 0 !important;
  padding: 0 !important;
  /* overflow: hidden !important; */
}

/* .b-grid-panel-body {
  background: #f7f7f7 !important;
} */

.gantt-preset-header {
  background-color: #f7f7f7;
  border-inline-start: none !important;
}

.b-column-line {
  border-inline-start: 1px dashed rgba(78, 101, 120, 0.55);
}

.b-column-line-major {
  border-inline-start: 2px dashed rgba(60, 54, 54, 0.55);
}

.resourceinfo-header {
  background-color: #f7f7f7 !important;
}
.b-sch-resourcetimerange {
  pointer-events: all !important;
}

.b-menu * {
  background: #ffffff;
  color: #000;
}

.b-menu:hover * {
  background: #fff !important;
  color: #000;
}

.event-content-visibility {
  visibility: hidden;
}

.b-sch-event-wrap:hover .puck-onhover-visibility {
  visibility: visible !important;
}

.puck-onhover-visibility {
  visibility: hidden;
}

.b-sch-event-wrap:hover .event-container-visibility {
  visibility: visible;
}

.b-sch-event-wrap {
  height: auto !important;
}

.b-sch-dirty-new {
  overflow: hidden !important;
}

.event-menu-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 160% !important;
  letter-spacing: 0.15px !important;
  color: rgba(0, 17, 34, 0.6) !important;
  border-bottom: 2px solid rgba(0, 17, 34, 0.6) !important;
  width: 220px;
  padding-left: 16px;
  background: #eeeeee;
  pointer-events: none;
}

.event-menu-option {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.15px;
  color: rgba(4, 0, 34, 0.75);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-left: 16px;
}

.event-menu-title .b-menu-text {
  background: #eeeeee;
}

.event-menu-title .b-menu-text,
.event-menu-option .b-menu-text {
  margin-inline-start: 0 !important;
}

.b-menu {
  border-radius: 12px !important;
}

.special-css-class .b-sch-event-wrap.b-active .b-sch-event {
  outline: none !important;
}

.svgIcon {
  display: block;
  margin: auto;
}

.dispatch-gantt-day-header {
  border-inline-start: none !important;
  color: rgba(0, 17, 34, 0.6) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 140 !important;
  letter-spacing: 0.4px;
  font-family: 'Poppins';
  background: #f7f7f7;
  pointer-events: none;
}

.dispatch-gantt-hour-header {
  background: #f7f7f7;
  border-inline-start: none !important;
  border-inline-end: none !important;
  color: rgba(0, 17, 34, 0.6) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 140 !important;
  letter-spacing: 0.4px;
  justify-content: start !important;
  font-family: 'Poppins';
  pointer-events: none;
}

.b-sch-event-content {
  font-family: Poppins;
}

.b-sch-event-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.b-sch-event-content .need-load-puck:hover {
  border: 1px solid #2b64cb !important;
  background: #e7f0f7 !important;
}

.need-load-puck {
  justify-content: center;
}

.need-load-puck:hover > .need-load-puck-text {
  display: none !important;
}

.need-load-content-container {
  display: none;
}
.need-load-puck:hover > .need-load-content-container {
  display: block;
}

.need-load-content-container * {
  color: #2b64cb;
}

.disabled-button {
  border: 1px solid rgba(0, 17, 34, 0.12) !important;
}
.disabled-button > * {
  color: rgba(0, 17, 34, 0.26) !important;
}

.empty-select-trip {
  cursor: default;
}

.b-grid-row:hover .resource-column-name {
  background: rgba(33, 150, 243, 0.13);
  border-radius: 6px;
  padding: 0px 4px;
}
.resource-reset-icon {
  display: none !important;
}
.b-grid-row:hover .resource-column-name .resource-reset-icon {
  display: flex !important;
}

.b-gridbase:not(.b-moving-splitter)
  .b-grid-subgrid:not(.b-timeaxissubgrid)
  .b-grid-row:not(.b-group-row).b-hover {
  background: rgba(33, 150, 243, 0.08) !important;
}
